/*
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-08-15 14:12:33
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-08-15 15:58:28
 * @FilePath: \omp-webe:\sirun\pro-management\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-08-15 14:12:33
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-08-15 14:35:37
 * @FilePath: \omp-webe:\sirun\pro-management\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import Router from "vue-router";
import ManagerView from "../views/manage";
import ProView from "../views/production";
import WorkView from "../views/workHour";

Vue.use(Router);

export default new Router({
  mode: "history", // 使用HTML5的History路由模式
  routes: [
    {
      path: "/manager",
      name: "Manager",
      component: ManagerView,
    },
    {
      path: "/pro",
      name: "Pro",
      component: ProView,
    },
    {
      path: "/work",
      name: "Work",
      component: WorkView,
    },
    {
      path: "*",
      redirect: "/manager", // 默认重定向到项目管理看板
    },
  ],
});