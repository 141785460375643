<!--
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-08-14 17:07:20
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-08-19 19:17:28
 * @FilePath: \omp-webe:\sirun\pro-management\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <a-menu
      v-model="current"
      theme="dark"
      mode="horizontal"
      @click="handleClick"
    >
      <a-menu-item key="manage"> 项目管理看板 </a-menu-item>
      <a-menu-item key="pro"> 项目生产看板 </a-menu-item>
      <a-menu-item key="work"> 项目工时看板 </a-menu-item>
    </a-menu>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      current: ["manage"], // 默认选中第一个菜单项
    };
  },
  methods: {
    handleClick(e) {
      this.current = [e.key];
      this.$router.push(`/${e.key}`);
    },
  },
  created() {
    console.log("created", this.$route.name.toLowerCase());
    this.current = [this.$route.name.toLowerCase()]; // 页面刷新时，保持选中的菜单项与路由同步
  },
};
</script>

<style>
.app {
  font-family: "Arial", sans-serif;
  background-color: #f7f9fb;
}
</style>
