<!--
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-08-15 14:44:04
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-08-21 11:21:20
 * @FilePath: \omp-webe:\sirun\pro-management\src\views\workHour\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="workHour">
    <a-collapse
      v-model="activeWorkKey"
      accordion
      expand-icon-position="right"
      style="margin-top: 20px"
      @change="handleCollapseChange"
    >
      <template #expandIcon="props">
        <a-icon
          style="color: #168fff"
          type="caret-right"
          :rotate="props.isActive ? 90 : 0"
        />
      </template>
      <a-collapse-panel v-for="item in managerList" :key="item.ProjectID">
        <template #header>
          <span class="collapse-container">
            <p class="collapse-highlight">项目名称: {{ item.ProjectName }}</p>
            <p>项目里程: {{ item.ProjectStage }}</p>
            <p>完成时间:{{ formatDate(item.CompletionDate) }}</p>
          </span>
        </template>
        <div>
          <a-descriptions :column="1" style="margin-left: 1%">
            <a-descriptions-item label="参与人数">
              <span
                v-for="(it, index) in workHoursInfo.top_assignees"
                :key="index"
              >
                {{ it }},
              </span>
            </a-descriptions-item>
            <a-descriptions-item label="预期工时">
              {{ workHoursInfo.total_original_estimate }}
            </a-descriptions-item>
            <a-descriptions-item label="实际工时">
              {{ workHoursInfo.total_time_spent }}
            </a-descriptions-item>
          </a-descriptions>
          <a-row
            style="
              padding: 10px;
              border: 1px solid #e8ecef;
              border-radius: 8px;
              margin-bottom: 10px;
            "
            :gutter="16"
            v-if="workHoursInfo.details && workHoursInfo.details.length > 0"
          >
            <a-col
              :span="6"
              v-for="item in workHoursInfo.details"
              :key="item.issueID"
            >
              <div class="green-card">
                <span
                  >&nbsp;<strong>{{ item.Summary }}</strong></span
                >
                <span
                  ><a-icon type="user" />&nbsp;责任人:&nbsp;{{
                    item.Assignee
                  }}</span
                >
                <span
                  ><a-icon type="file-protect" />&nbsp;预计工时:<strong
                    style="margin-left: 5%"
                    >{{ item.original_estimate }}</strong
                  ></span
                >
                <span
                  ><a-icon type="file-protect" />&nbsp;实际工时:<strong
                    style="margin-left: 5%"
                    >{{ item.remaining_estimate }}</strong
                  ></span
                >
                <a
                  v-if="item.subtasks.length > 0"
                  style="align-self: flex-end"
                  @click="getSubtasksInfo(item.subtasks)"
                >
                  Show Subtasks<a-icon type="bars"
                /></a>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-collapse-panel>
    </a-collapse>
    <a-modal
      v-model="visible"
      cancelText="关闭"
      okText="确认"
      title="子任务详情"
      @ok="handleOk"
    >
      <a-row :gutter="16" v-if="subTasksInfo && subTasksInfo.length > 0">
        <a-col :span="12" v-for="item in subTasksInfo" :key="item.issueID">
          <div class="green-card">
            <span
              >&nbsp;<strong>{{ item.Summary }}</strong></span
            >
            <span
              ><a-icon type="user" />&nbsp;责任人:&nbsp;{{
                item.Assignee
              }}</span
            >
            <span
              ><a-icon type="file-protect" />&nbsp;预计完成时间:<strong
                style="margin-left: 5%"
                >{{ item.original_estimate }}</strong
              ></span
            >
            <span
              ><a-icon type="file-protect" />&nbsp;实际完成时间:<strong
                style="margin-left: 5%"
                >{{ item.remaining_estimate }}</strong
              ></span
            >
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import api from "../../api/index.js";
import moment from "moment";
export default {
  name: "workHourPage",
  data() {
    return {
      activeWorkKey: null,
      managerList: [],
      subTasksInfo: [],
      visible: false,
      workHoursInfo: {},
    };
  },
  mounted() {
    this.getManagerList();
  },
  methods: {
    getManagerList() {
      api.getManagerList().then((res) => {
        this.managerList = res;
        console.log("this.managerList", this.managerList);
      });
    },

    handleCollapseChange(activeWorkKey) {
      console.log("当前激活的面板 key:", activeWorkKey);
      if (activeWorkKey) {
        const params = {
          id: activeWorkKey,
        };
        api.getWorkHoursDetail(params).then((res) => {
          this.workHoursInfo = res;
          console.log("res-info", res);
        });
      }
    },
    getSubtasksInfo(info) {
      this.subTasksInfo = info;
      this.visible = true;
    },
    handleOk() {
      this.visible = false;
    },
    formatDate(dateString) {
      if (dateString === null) return "-";
      return moment(dateString).format("YYYY-MM-DD"); // 格式化为 yyyy-mm-dd
    },
  },
};
</script>

<style>
.workHour {
  width: 100%;
  height: 100vh;
}
.green-card {
  background-color: #f9fefb;
  border: 1px solid #2dd376;
  padding: 10px;
  height: 160px;
  margin-bottom: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
