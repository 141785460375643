<!--
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-08-15 14:36:10
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-08-21 11:16:16
 * @FilePath: \omp-webe:\sirun\pro-management\src\views\manage\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="manage">
    <a-collapse
      v-model="activeKey"
      accordion
      expand-icon-position="right"
      style="margin-top: 20px"
      @change="handleCollapseChange"
    >
      <template #expandIcon="props">
        <a-icon
          style="color: #168fff"
          type="caret-right"
          :rotate="props.isActive ? 90 : 0"
      /></template>
      <a-collapse-panel v-for="item in managerList" :key="item.ProjectKey">
        <template #header>
          <span class="collapse-container">
            <p class="collapse-highlight">{{ item.ProjectName }}</p>
            <p>完成度: {{ formatNumber(item.CompletionRate) }}%</p>
            <p>项目里程: {{ item.ProjectStage }}</p>
            <p>预期数量: {{ item.Exp_num }}</p>
            <p>完成时间： {{ formatDate(item.CompletionDate) }}</p>
          </span>
        </template>
        <div>
          <a-row
            style="
              margin-bottom: 10px;
              padding: 10px;
              border: 1px solid #e8ecef;
              border-radius: 8px;
            "
            :gutter="16"
            v-if="projectInfo.development && projectInfo.development.length > 0"
          >
            <a-col :span="24" style="font-weight: bold; margin-bottom: 5px">
              开发
            </a-col>

            <a-col
              :span="6"
              v-for="item in projectInfo.development"
              :key="item.issueID"
            >
              <div :class="cardClass(item.Status)">
                <span
                  >(任务)&nbsp;<strong>{{ item.Summary }}</strong></span
                >
                <span
                  ><a-icon type="user" />&nbsp;责任人:&nbsp;{{
                    item.Assignee
                  }}</span
                >
                <span
                  ><a-icon type="file-protect" />&nbsp;预计完成时间:<strong
                    style="margin-left: 5%"
                    >{{ formatDate(item.ExpectedCompletionDate) }}</strong
                  ></span
                >
                <span
                  ><a-icon type="file-protect" />&nbsp;实际完成时间:<strong
                    style="margin-left: 5%"
                    >{{ formatDate(item.ActualCompletionDate) }}</strong
                  ></span
                >
                <a
                  v-if="item.Question_type === '系统需求'"
                  style="align-self: flex-end"
                  @click="getSubtasksInfo(item.IssueID)"
                >
                  Show Subtasks<a-icon type="bars"
                /></a>
              </div>
            </a-col>
          </a-row>
          <a-row
            style="
              padding: 10px;
              border: 1px solid #e8ecef;
              border-radius: 8px;
              margin-bottom: 10px;
            "
            :gutter="16"
            v-if="projectInfo.testing && projectInfo.testing.length > 0"
          >
            <a-col :span="24" style="font-weight: bold; margin-bottom: 5px">
              测试
            </a-col>

            <a-col
              :span="6"
              v-for="item in projectInfo.testing"
              :key="item.issueID"
            >
              <div :class="cardClass(item.Status)">
                <span
                  >(任务)&nbsp;<strong>{{ item.Summary }}</strong></span
                >
                <span
                  ><a-icon type="user" />&nbsp;责任人:&nbsp;{{
                    item.Assignee
                  }}</span
                >
                <span
                  ><a-icon type="file-protect" />&nbsp;预计完成时间:<strong
                    style="margin-left: 5%"
                    >{{ formatDate(item.ExpectedCompletionDate) }}</strong
                  ></span
                >
                <span
                  ><a-icon type="file-protect" />&nbsp;实际完成时间:<strong
                    style="margin-left: 5%"
                    >{{ formatDate(item.ActualCompletionDate) }}</strong
                  ></span
                >
                <a
                  v-if="item.Question_type === '系统需求'"
                  style="align-self: flex-end"
                  @click="getSubtasksInfo(item.IssueID)"
                >
                  Show Subtasks<a-icon type="bars"
                /></a>
              </div>
            </a-col>
          </a-row>
          <a-row
            style="padding: 10px; border: 1px solid #e8ecef; border-radius: 8px"
            :gutter="16"
            v-if="
              projectInfo.project_management &&
              projectInfo.project_management.length > 0
            "
          >
            <a-col :span="24" style="font-weight: bold; margin-bottom: 5px">
              项目管理
            </a-col>

            <a-col
              :span="6"
              v-for="item in projectInfo.project_management"
              :key="item.issueID"
            >
              <div :class="cardClass(item.Status)">
                <span
                  >(任务)&nbsp;<strong>{{ item.Summary }}</strong></span
                >
                <span
                  ><a-icon type="user" />&nbsp;责任人:&nbsp;{{
                    item.Assignee
                  }}</span
                >
                <span
                  ><a-icon type="file-protect" />&nbsp;预计完成时间:<strong
                    style="margin-left: 5%"
                    >{{ formatDate(item.ExpectedCompletionDate) }}</strong
                  ></span
                >
                <span
                  ><a-icon type="file-protect" />&nbsp;实际完成时间:<strong
                    style="margin-left: 5%"
                    >{{ formatDate(item.ActualCompletionDate) }}</strong
                  ></span
                >
                <a
                  v-if="item.Question_type === '系统需求'"
                  style="align-self: flex-end"
                  @click="getSubtasksInfo(item.IssueID)"
                >
                  Show Subtasks<a-icon type="bars"
                /></a>
              </div>
            </a-col>
          </a-row>
          <a-row
            style="padding: 10px"
            type="flex"
            justify="space-between"
            v-if="
              projectInfo.production_data &&
              projectInfo.production_data.length > 0
            "
          >
            <a-col :span="24" style="font-weight: bold; margin-bottom: 5px">
              生产
            </a-col>
            <a-col :span="24" style="margin-bottom: 10px">
              <a-progress :strokeWidth="15" :percent="progressPercent" />
            </a-col>
            <a-col :span="24">
              <span
                >订单名称<strong style="padding: 0 10px">{{
                  projectInfo.production_data[0].ProjectName
                }}</strong
                >预期量<strong style="padding: 0 10px">{{
                  projectInfo.production_data[0].Qty
                }}</strong
                >订单号<strong style="padding: 0 10px">{{
                  projectInfo.production_data[0].ProjectNumber
                }}</strong></span
              >
            </a-col>
          </a-row>
        </div>
      </a-collapse-panel>
    </a-collapse>
    <a-modal
      v-model="visible"
      cancelText="关闭"
      okText="确认"
      title="子任务详情"
      @ok="handleOk"
    >
      <a-row :gutter="16" v-if="subTasksInfo && subTasksInfo.length > 0">
        <a-col :span="12" v-for="item in subTasksInfo" :key="item.issueID">
          <div :class="cardClass(item.Status)">
            <span
              >(任务)&nbsp;<strong>{{ item.Summary }}</strong></span
            >
            <span
              ><a-icon type="user" />&nbsp;责任人:&nbsp;{{
                item.Assignee
              }}</span
            >
            <span
              ><a-icon type="file-protect" />&nbsp;预计完成时间:<strong
                style="margin-left: 5%"
                >{{ formatDate(item.ExpectedCompletionDate) }}</strong
              ></span
            >
            <span
              ><a-icon type="file-protect" />&nbsp;实际完成时间:<strong
                style="margin-left: 5%"
                >{{ formatDate(item.ActualCompletionDate) }}</strong
              ></span
            >
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import api from "../../api/index.js";
import moment from "moment";
export default {
  name: "managePage",
  data() {
    return {
      activeKeyTest: null,
      activeKey: null,
      visible: false,
      managerList: [],
      subTasksInfo: [],
      percent: 0,
      projectInfo: {},
    };
  },
  watch: {
    activeKey(key) {
      console.log(key);
    },
  },
  mounted() {
    this.getManagerList();
  },
  computed: {
    progressPercent() {
      if (this.projectInfo.production_data) {
        const data = this.projectInfo.production_data[0];
        const qty = data.Qty;
        const qualifiedInQty = data.QualifiedInQty;
        return qualifiedInQty > 0 ? (qty / qualifiedInQty) * 100 : 0;
      }
      return 0;
    },
  },
  methods: {
    getManagerList() {
      api.getManagerList().then((res) => {
        this.managerList = res;
        console.log("this.managerList", this.managerList);
      });
    },
    handleCollapseChange(activeKey) {
      if (activeKey) {
        console.log("当前激活的面板 key:", activeKey);
        const foundItem = this.managerList.find(
          (item) => item.ProjectKey === activeKey
        );

        console.log("选中的item", foundItem);
        const params = {
          key: foundItem.ProjectKey,
          id: foundItem.ProjectID,
        };
        api.getManagerInfo(params).then((res) => {
          this.projectInfo = res;
          console.log("res-info", res);
        });
      }

      // 处理点击事件的逻辑
    },
    handleOk() {
      this.visible = false;
    },
    getSubtasksInfo(key) {
      const params = { id: key };
      api.getSubtasks(params).then((res) => {
        this.subTasksInfo = res;
        this.visible = true;
        console.log("res", res);
      });
    },
    formatNumber(value) {
      return value.toFixed(2); // 保留两位小数
    },
    formatDate(dateString) {
      if (dateString === null) return "-";
      return moment(dateString).format("YYYY-MM-DD"); // 格式化为 yyyy-mm-dd
    },
    cardClass(statusInfo) {
      // 根据传入的状态值返回相应的 CSS 类
      switch (statusInfo) {
        case "已解决":
          return "green-card";
        case "已取消":
          return "green-card";
        case "挂起":
          return "green-card";
        case "拒绝":
          return "green-card";
        case "不需解决":
          return "green-card";
        case "重复问题":
          return "green-card";
        case "延后解决":
          return "green-card";
        case "已完成":
          return "green-card";
        default:
          return "bule-card";
      }
    },
  },
};
</script>

<style>
.manage {
  width: 100%;
  height: 100vh;
}
.green-card {
  background-color: #f9fefb;
  border: 1px solid #2dd376;
  padding: 10px;
  height: 160px;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.red-card {
  background-color: #fff9f9;
  border: 1px solid #ff4444;
  padding: 10px;
  height: 160px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bule-card {
  background-color: #f9fcff;
  border: 1px solid #2fa3ff;
  padding: 10px;
  height: 160px;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.collapse-container {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  gap: 20px; /* 每个元素之间的间隔 */
}

.collapse-highlight {
  color: #2fa3ff;
  font-weight: bold;
}
</style>
