<!--
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-08-15 14:44:03
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-08-21 12:28:01
 * @FilePath: \omp-webe:\sirun\pro-management\src\views\production\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div
      style="
        margin-top: 20px;
        margin-left: 20px;
        font-size: 24px;
        font-family: 'Arial', sans-serif;
      "
    >
      成品生产总数量: <strong>{{ totalNum }}</strong>
    </div>
    <a-collapse
      v-model="activeKey"
      accordion
      expand-icon-position="right"
      style="margin-top: 20px"
      @change="handleCollapseChange"
    >
      <template #expandIcon="props">
        <a-icon
          style="color: #168fff"
          type="caret-right"
          :rotate="props.isActive ? 90 : 0"
      /></template>
      <a-collapse-panel v-for="item in proList" :key="item.OrderNo">
        <template #header>
          <span class="collapse-container">
            <p class="collapse-highlight">{{ item.ProjectName }}</p>
            <p>预期生产数量: {{ item.Qty }}</p>
            <p>入库数量: {{ item.QualifiedInQty }}</p>
            <p>状态: {{ getStatusText(item.Status) }}</p>
          </span>
        </template>
        <a-list item-layout="horizontal" :data-source="workInfo">
          <a-list-item slot="renderItem" slot-scope="item">
            <div v-if="item.Flg==='1'" class="collapse-container"  style="color: red;" >
              <p>子料名称: {{ item.CMaterialName }}</p>
              <p>预期数量: {{ item.Qty }}</p>
              <p>实际数: {{ item.QualifiedInQty }}</p>
              <p>子件退料数量: {{ item.ReturnQty }}</p>
              <p>损坏数量: {{ item.UnPlanQty }}</p>
              <p>配比: {{ item.QtyPerAssembly }}</p>
              <p>料类别: {{ item.SupplyType }}</p>
            </div>
            <div v-else class="collapse-container" >
              <p>子料名称: {{ item.CMaterialName }}</p>
              <p>预期数量: {{ item.Qty }}</p>
              <p>实际数: {{ item.QualifiedInQty }}</p>
              <p>子件退料数量: {{ item.ReturnQty }}</p>
              <p>损坏数量: {{ item.UnPlanQty }}</p>
              <p>配比: {{ item.QtyPerAssembly }}</p>
              <p>料类别: {{ item.SupplyType }}</p>
            </div>
          </a-list-item>
        </a-list>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
const data = [
  {
    name: "上壳",
  },
  {
    name: "上壳",
  },
  {
    name: "上壳",
  },
  {
    name: "上壳",
  },
];
import api from "../../api/index.js";
export default {
  name: "productionPage",
  data() {
    return {
      data,
      totalNum: 0,
      activeKey: null,
      workInfo: [],
      proList: [],
    };
  },
  mounted() {
    this.getTotal();
    this.getManagerList();
  },
  methods: {
    getTotal() {
      api.getTotal().then((res) => {
        console.log("测试输出", res);
        this.totalNum = res.total_qualified_in_qty;
      });
    },
    getManagerList() {
      api.getProList().then((res) => {
        this.proList = res;
        console.log("this.proList", this.proList);
      });
    },
    handleCollapseChange(activeKey) {
      console.log("当前激活的面板 key:", activeKey);
      if (activeKey) {
        const params = {
          id: activeKey,
        };
        api.getWorkDetail(params).then((res) => {
          this.workInfo = res;
          console.log("res-info", res);
        });
      }
    },
    getStatusText(statusCode) {
      // 状态码与文本的映射
      const statusMap = {
        1: "开立",
        2: "锁定",
        3: "审核",
        4: "关闭",
      };

      // 返回对应的状态文本，如果状态码不在映射中，则返回 '未知状态'
      return statusMap[statusCode] || "未知状态";
    },
  },
};
</script>

<style>
.production {
  width: 100%;
  height: 100vh;
}
.collapse-container {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  gap: 20px; /* 每个元素之间的间隔 */
}

.collapse-highlight {
  color: #2fa3ff;
  font-weight: bold;
}
</style>
