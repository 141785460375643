/*
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-08-19 18:43:13
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-08-21 08:59:40
 * @FilePath: \omp-webe:\sirun\pro-management\src\api\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/api/request";
export default {
  //获取项目管理看板列表
  getManagerList() {
    return request({
      url: "/projects",
      method: "get",
    });
  },
  //获取项目管理看板列表
  getProList() {
    return request({
      url: "/api/workorderdata",
      method: "get",
    });
  },
  //获取项目管理看板信息
  getManagerInfo(parmas) {
    return request({
      url: "/issues/" + parmas.id + "/" + parmas.key,
      method: "get",
    });
  },
  //获取子任务信息
  getSubtasks(parmas) {
    return request({
      url: "/subtasks/" + parmas.id,
      method: "get",
    });
  },
  //单个批次里面每个用料情况
  getWorkDetail(parmas) {
    return request({
      url: "/api/workorderdetails/" + parmas.id,
      method: "get",
    });
  },
  //项目工时详情
  getWorkHoursDetail(parmas) {
    return request({
      url: "/issues-subtasks/" + parmas.id,
      method: "get",
    });
  },
  //获取成品生产总数量:
  getTotal() {
    return request({
      url: "/api/workorderdata/QualifiedInQty/total",
      method: "get",
    });
  },
};
