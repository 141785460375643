import axios from "axios";
import { message } from "ant-design-vue";

// 创建axios实例
const service = axios.create({
  baseURL: "https://info.pm.sirun.net", // api的base_url
  timeout: 5000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 可以在这里添加请求头等信息
    return config;
  },
  (error) => {
    // 请求错误处理
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 对响应数据做处理，例如只返回data部分
    const res = response.data;
    // 根据返回的状态码做相应处理，例如401未授权等
    return res;
  },
  (error) => {
    // 响应错误处理
    message.error(error || "服务器出错");
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);

export default service;
