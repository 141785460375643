/*
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-08-14 17:07:20
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-08-15 15:55:42
 * @FilePath: \omp-webe:\sirun\pro-management\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import router from "../src/router";
import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false
Vue.use(Antd);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
